<script>
import appConfig from "@/app.config";
import helper from "@/assets/helpers/helper";
import Swal from "sweetalert2";
/**
 * Recover password Sample page
 */
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  animation: false,
  timer: 2000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export default {
  page: {
    title: "Email Confirmation ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  name:"confirmationEmail",
  data(){
    return{
      code:''
    }
  },
  methods:{
   async resend(){
      await helper.getReturnData('email/resend')
    },
     sendCode(){
      this.$http
          .post('email/verify',{'code': this.code})
          .then(response => {
            console.log(response)
            this.$store.commit('user',response.data)
            this.$router.push({'name':'FirstInfos'})
          })
          .catch(error => {
            Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
          })
    }


  }

};
</script>

<template>

    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">

              <div class="row mx-0">
                <div class="col-12">
                  <div class="text-white px-3 pt-5">
                    <h3 class="text-white ">{{$t('logins.confirm_email')}}</h3>
                    <p class="text-white pt-3" >{{$t('logins.check')}}</p>
                  </div>
                </div>

              </div>
              <div class="card-body ">

                <div class="p-2">

                  <form class="form-horizontal" id="form1"  method="POST" @submit.prevent="sendCode" >
                    <div class="form-group">
                      <label >Code</label>
                      <input
                          @keyup.enter="sendCode"
                          v-model="code"
                          type="text"
                          class="form-control"
                          :placeholder="$t('logins.code_text')"
                      />
                    </div>

                    <div class="form-group float-right row mx-0">
                      <!-- <div class="col-6 py-2">
                        <a href="#"  @click="recover">{{$t('logins.resend')}}</a>
                      </div> -->
                      <input type="submit" class="btn btn-primary w-md" :value="$t('pwd.save')"/>

                    </div>
                  </form>
                  <div class="mt-4 ">
                    <a href="#" @click="resend">{{$t('logins.resend')}}</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>