<script>
import appConfig from "@/app.config";
import helper from "@/assets/helpers/helper";
import {required, sameAs} from "vuelidate/lib/validators";
import Vue from "vue";
import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  animation: false,
  timer: 2000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

/**
 * Recover password Sample page
 */
export default {
  page: {
    title: "Recover password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  name:"RecoverPassword",

  data(){
            return{
                email:'',
                code:'',
              state:1,
              typesubmit: false,
              new_password: "",
              confirmPassword: "",

            }
        },
  validations: {
    new_password: {required},
    confirmPassword: {required, sameAsPassword: sameAs("new_password")}
  },
        methods: {
           async recover(){
             let re =  await helper.postReturnData('password/reset', {'email': this.email})
             if(re) this.state = 2
            },
          async sendCode(){
            let re =  await helper.postReturnData('password/code', {'token': this.code,'email': this.email})
            if(re) this.state = 3
          },
          handleSubmit() {
            this.typesubmit = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
              return;
            }
            this.setPassword();

          },
          setPassword() {

            this.$http
                .post("change-password",
                    {
                      password:this.new_password,
                      token:this.code,
                      email:this.email
                    })
                .then(response => {
                  console.log(response)
                  const token = response.data.token;
                  console.log(token);
                  this.$store.commit('user',response.data.user);
                  localStorage.setItem('token', token)
                  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
                  this.$store.commit('updatePusher',token)
                  this.$router.push({'name': 'Dashboard'})
                })
                .catch(error => {
                  Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                  this.state=1
                })
          }
        }
};
</script>

<template>

    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
                <div class="row">
                  <div class="col-12">
                    <div class="text-white p-4">
                      <h3 >{{$t('logins.reset_title')}}</h3>
                      <p>{{$t('logins.reset_sub_title')}}</p>
                    </div>
                  </div>

                </div>
              <div class="card-body pt-0">

                <div class="p-2">
                  <!-- <div class="alert    text-center mb-4" role="alert" v-if="!send">{{$t('logins.msg')}}</div> -->
                  <form class="form-horizontal" id="form"  method="POST" @submit.prevent="recover" v-if="state == 1">
                    <div class="form-group">
                      <label for="useremail">{{$t('logins.email')}}</label>
                      <input
                          @keyup.enter="recover"
                          v-model="email"
                          type="email"
                          class="form-control"
                          id="useremail"
                          :placeholder="$t('logins.email_text')"
                      />
                    </div>

                    <div class="form-group float-right row mx-0 ">
                      <!-- <div class="col-6 py-2">
                        <a href="#"  @click="recover">{{$t('logins.resend')}}</a>
                      </div> -->
                      <input type="submit" class="btn btn-primary w-md" :value="$t('logins.reset')"/>

                    </div>
                  </form>

                  <div  v-if="state == 2">
                    <div class="alert alert-info text-center mb-4" role="alert">{{$t('logins.msg2')}}</div>

                    <form class="form-horizontal" id="form1"  method="POST" @submit.prevent="sendCode" >
                      <div class="form-group">
                        <label for="useremail">Code</label>
                        <input
                            @keyup.enter="recover"
                            v-model="code"
                            type="text"
                            class="form-control"
                            :placeholder="$t('logins.code_text')"
                        />
                      </div>

                      <div class="form-group float-right row mb-0">
                        <!-- <div class="col-6 py-2">
                          <a href="#"  @click="recover">{{$t('logins.resend')}}</a>
                        </div> -->
                        <input type="submit" class="btn btn-primary w-md" :value="$t('pwd.save')"/>

                      </div>
                    </form>
                  </div>
                  <form id="form3"  method="POST" @submit.prevent="handleSubmit" v-if="state ==3">
                    <div class="form-group  mb-4">
                      <label>New Password :</label>
                      <b-form-input type="password" v-model="new_password"
                                    name="new_password"
                                    class="form-control"
                                    :class="{ 'is-invalid': typesubmit && $v.new_password.$error }"
                                    :placeholder="$t('pwd.place_holder2')"
                      ></b-form-input>
                      <div v-if="typesubmit && $v.new_password.$error" class="invalid-feedback">
                        <span v-if="!$v.new_password.required">{{$t('pwd.required')}}</span>
                      </div>
                    </div>
                    <div class="form-group  mb-4">
                      <label> Verify Password :</label>
                      <b-form-input type="password" v-model="confirmPassword"
                                    name="confirmPassword"
                                    class="form-control"
                                    :class="{ 'is-invalid': typesubmit && $v.confirmPassword.$error }"
                                    :placeholder="$t('pwd.place_holder3')"></b-form-input>
                      <div v-if="typesubmit && $v.confirmPassword.$error"
                           class="invalid-feedback">
                        <span v-if="!$v.confirmPassword.required">{{$t('pwd.required')}}</span>
                        <span
                            v-else-if="!$v.confirmPassword.sameAsPassword"
                        >{{$t('pwd.same')}}</span>
                      </div>
                    </div>
                    <div class="form-group mt-3 mb-0">
                      <input type="submit" class="btn float-right btn-primary" :value="$t('pwd.save')"/>
                    </div>
                  </form>

                </div>
              </div>
            </div>

            <div class="mt-5 text-center">
              <p>
                {{$t('logins.remember_it')}}
                <router-link
                  tag="a"
                  to="/login"
                  class="font-weight-medium text-primary"
                > {{$t('logins.sign_in_here')}}</router-link>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>

</template>
<style scoped>
i{
  font-size: 60px;
}
</style>